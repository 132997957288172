import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { faChevronDown, faMapLocationDot, faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

import { LayoutContext } from '@/contexts/LayoutContext';
import { Button, ButtonType, GenericHtml, Spinner } from '@lamesarv-sdk/components';
import { SearchParamAction, useCustomSearchParams } from '@lamesarv-sdk/hooks';
import { getFacetCode } from '@lamesarv-sdk/tools';

import { searchVenues } from '@/tools/events';
import { useGetVenues } from '@/hooks/events';

import { IComponentBase, PropsWithClasses } from '@lamesarv-sdk/types';
import { IVenue } from '@/types/cms';

export type IVenueFilter = { label: string; value: string };

export interface IVenueSearchProps {
  callbackSearch: (query: string) => void;
  placeholder?: string;
}

export const VenueSearch = (props: IVenueSearchProps) => {
  const handleSearch = async (e: SyntheticEvent) => {
    e.preventDefault();
    const value = ((e.target as HTMLFormElement).query as HTMLInputElement).value;

    props.callbackSearch(value);
  };

  return (
    <form className="w-full flex flex-row" onSubmit={handleSearch}>
      <input
        type="search"
        name="query"
        placeholder={props.placeholder ?? 'Search'}
        className="w-full py-2 px-4 border border-gray-200 border-r-0 rounded-sm focus:ring-gray-400"
      />
      <Button
        type={ButtonType.flat}
        icon={<FontAwesomeIcon icon={faMagnifyingGlass} className="w-4 h-4 text-white" />}
        htmlType="submit"
        title="Search"
        className="bg-neutral-900 text-white py-2 px-4 rounded-l-none rounded-r-sm hover:bg-neutral-800 focus:outline-none"
      />
    </form>
  );
};

export const VenueItem = (props: IVenue) => {
  const { context } = useContext(LayoutContext);

  return (
    <a
      href={`/venues/${props.slug}`}
      className="grid grid-cols-1 items-center rounded-sm drop-shadow-sm p-6 border border-gray-1 md:grid-cols-4 gap-8 items-start hover:shadow-md"
    >
      <div className="col-span-1">
        <div className="flex flex-row gap-2 items-center mb-2">
          <FontAwesomeIcon icon={faMapLocationDot} className="w-10 h-10 text-gray-300 group-hover:text-black" />
          <div className="flex flex-col">
            <h2 className="text-xl font-bold">
              <span className="underline-offset-4 decoration-dashed	text-black">{props.title}</span>
            </h2>
            <p className="text-gray-600">
              {props.city}, {props.state}
            </p>
          </div>
        </div>
      </div>
      <div className="col-span-2">
        <GenericHtml content={props.features} />
      </div>
      {props.image && (
        <div className="col-span-1">
          <img src={props.image} alt="Venue Image" className="w-full object-cover rounded-sm" />
        </div>
      )}
      {!props.image && (
        <div className="col-span-1">
          <img src={context.logoUrl} alt="Venue Image" className="w-full p-16 rounded-sm bg-gray-900" />
        </div>
      )}
    </a>
  );
};

export interface IVenueFilterProps extends PropsWithClasses {
  title: string;
  items: IVenueFilter[];
  itemsCurrent: string[];
  callbackSelect: (values: string[]) => void;
}

export const VenueFilter = (props: IVenueFilterProps) => {
  const handleSelect = (e: SyntheticEvent) => {
    const value = (e.target as HTMLInputElement).value;

    if (props.itemsCurrent.includes(value)) {
      props.callbackSelect(props.itemsCurrent.filter((item) => item !== value));
    } else {
      props.callbackSelect([...props.itemsCurrent, value]);
    }
  };

  return (
    <Popover className={twMerge('relative border py-2.5 px-3 bg-white', props.className)}>
      <PopoverButton
        className={twMerge(
          'w-full flex flex-row gap-1 items-center justify-between text-sm font-medium focus:outline-none',
          props.classInput,
        )}
      >
        <span className={props.classTitle}>{props.title}</span>
        <FontAwesomeIcon icon={faChevronDown} className={twMerge('w-4 h-4 text-gray-300', props.classIcon)} />
        {props.itemsCurrent.length > 0 && (
          <div className="absolute -top-2 -right-2 leading-none text-xs text-white bg-red-600 py-1 px-2 rounded-full">
            {props.itemsCurrent.length}
          </div>
        )}
      </PopoverButton>
      <PopoverPanel className="absolute w-full right-0 z-10 mt-4 origin-top-right rounded-md bg-white p-4 shadow-2xl transition md:w-auto focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
        <div className="flex flex-col gap-2">
          {!props.items.length && (
            <span className="text-sm font-medium text-gray-300 whitespace-nowrap">No items available</span>
          )}
          {props.items.map((item, index) => (
            <label key={index} className="flex flex-row gap-2 items-center">
              <input
                type="checkbox"
                value={item.value}
                checked={props.itemsCurrent.includes(item.value)}
                onChange={handleSelect}
                className="h-8 w-8 rounded border-gray-300 text-indigo-600 md:h-4 md:w-4 focus:ring-indigo-500"
              />
              <span className="text-sm font-medium text-gray-900 capitalize whitespace-nowrap">{item.label}</span>
            </label>
          ))}
        </div>
      </PopoverPanel>
    </Popover>
  );
};

export interface IVenueFilterPillProps {
  prefix: string;
  title: string;
  value: string;
  callbackRemove: (value: string) => void;
}

export const VenueFilterPill = (props: IVenueFilterPillProps) => {
  return (
    <div className="relative flex flex-row items-center rounded-full border border-gray-300 bg-white">
      <div className="text-xs text-gray-400 bg-gray-200 py-1.5 pl-3 pr-2 rounded-l-full">{props.prefix}</div>
      <div className="text-xs py-1.5 px-2">{props.title}</div>
      <FontAwesomeIcon
        icon={faXmark}
        className="w-4 h-4 pr-3 cursor-pointer"
        onClick={() => props.callbackRemove(props.value)}
      />
    </div>
  );
};

export interface IComponentVenuesList extends IComponentBase, PropsWithClasses {
  filterByState?: string;
  filterByCity?: string;
  displaySearch?: boolean;
  displayFilterState?: boolean;
  displayFilterCity?: boolean;
  displayFilterVenue?: boolean;
  classHeader?: string;
}

export const VenuesList = (props: IComponentVenuesList) => {
  const { searchParams, updateSearchParams } = useCustomSearchParams();

  const { isLoading, items: venuesBase, fetchItems } = useGetVenues({});

  const _query = searchParams.get('q') ? searchParams.get('q') : '';

  const _selectedStates = props.filterByState
    ? [getFacetCode(props.filterByState)]
    : searchParams.get('state')
      ? searchParams.get('state').split(',').filter(Boolean)
      : [];

  const _selectedCities = props.filterByCity
    ? [getFacetCode(props.filterByCity)]
    : searchParams.get('city')
      ? searchParams.get('city').split(',').filter(Boolean)
      : [];

  const [query, setQuery] = useState<string>(_query);
  const [selectedStates, setSelectedStates] = useState<string[]>(_selectedStates);
  const [selectedCities, setSelectedCities] = useState<string[]>(_selectedCities);

  const [items, setItems] = useState<IVenue[]>([]);

  const states = venuesBase.reduce((acc: IVenueFilter[], venue) => {
    if (!acc.find((item) => item.value === getFacetCode(venue.state))) {
      acc.push({
        label: venue.state,
        value: getFacetCode(venue.state),
      });
    }

    return acc;
  }, []);

  const cities = venuesBase.reduce((acc: IVenueFilter[], venue) => {
    if (
      selectedStates.includes(getFacetCode(venue.state)) &&
      !acc.find((item) => item.value === getFacetCode(venue.city))
    ) {
      acc.push({
        label: venue.city,
        value: getFacetCode(venue.city),
      });
    }

    return acc;
  }, []);

  const getFilterLabel = (filters: IVenueFilter[], value: string) => {
    return filters.find((item) => item.value === value)?.label;
  };

  const getItems = async () => {
    const baseItems = await fetchItems({
      amount: 1000,
    });

    const parsedItems = searchVenues(baseItems, query, selectedStates, selectedCities);

    setItems(parsedItems);
  };

  useEffect(() => {
    getItems();
  }, [query, selectedStates, selectedCities]);

  useEffect(() => {
    console.log('searchParams', Object.fromEntries(searchParams.entries()));
    console.log('_query', _query);
    console.log('_selectedStates', _selectedStates);
    console.log('_selectedCities', _selectedCities);
  }, [searchParams]);

  const displayHeader = props.displaySearch || props.displayFilterState || props.displayFilterCity;
  const displayFiltersList =
    (selectedStates.length > 0 && props.displayFilterState) || (selectedCities.length > 0 && props.displayFilterCity);

  return (
    <div className="flex flex-col" key={new Date().getMilliseconds()}>
      {displayHeader && (
        <div className={twMerge('bg-neutral-100 border-b border-neutral-200', props.classHeader)}>
          <div className="max-w-7xl space-y-12 mx-auto p-4 md:p-8">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2 justify-between items-center md:flex-row md:gap-4">
                {props.displaySearch && (
                  <VenueSearch callbackSearch={(newQuery: string) => setQuery(newQuery)} placeholder="Search Venues" />
                )}
                {(props.displayFilterState || props.displayFilterCity || props.displayFilterVenue) && (
                  <PopoverGroup className="w-full flex flex-col gap-2 items-center md:flex-row md:gap-4 md:w-auto">
                    {props.displayFilterState && (
                      <VenueFilter
                        title="States"
                        items={states}
                        itemsCurrent={selectedStates}
                        callbackSelect={(newSelectedStates: string[]) => {
                          updateSearchParams([
                            {
                              action: newSelectedStates.length ? SearchParamAction.add : SearchParamAction.remove,
                              key: 'state',
                              value: newSelectedStates.join(','),
                            },
                            { action: SearchParamAction.remove, key: 'city', value: '' },
                            { action: SearchParamAction.remove, key: 'venue', value: '' },
                          ]);
                          setSelectedStates(newSelectedStates);
                          setSelectedCities([]);
                        }}
                        className="w-full md:w-auto md:min-w-24"
                      />
                    )}
                    {props.displayFilterCity && (
                      <VenueFilter
                        title="Cities"
                        items={cities}
                        itemsCurrent={selectedCities}
                        callbackSelect={(newSelectedCities: string[]) => {
                          updateSearchParams([
                            { action: SearchParamAction.add, key: 'state', value: searchParams.get('state') },
                            {
                              action: newSelectedCities.length ? SearchParamAction.add : SearchParamAction.remove,
                              key: 'city',
                              value: newSelectedCities.join(','),
                            },
                            { action: SearchParamAction.remove, key: 'venue', value: '' },
                          ]);
                          setSelectedCities(newSelectedCities);
                        }}
                        className="w-full md:w-auto md:min-w-24"
                      />
                    )}
                  </PopoverGroup>
                )}
              </div>
              {displayFiltersList && (
                <div className="flex flex-row flex-wrap gap-2 items-center">
                  <span className="text-sm text-gray-500 font-medium">Filters:</span>
                  {props.displayFilterState &&
                    selectedStates.map((state, index) => (
                      <VenueFilterPill
                        key={index}
                        prefix="State"
                        title={getFilterLabel(states, state)}
                        value={state}
                        callbackRemove={(value: string) => {
                          const newSelectedStates = selectedStates.filter((item) => item !== value);
                          updateSearchParams([
                            {
                              action: newSelectedStates.length ? SearchParamAction.add : SearchParamAction.remove,
                              key: 'state',
                              value: newSelectedStates.join(','),
                            },
                            { action: SearchParamAction.remove, key: 'city', value: '' },
                            { action: SearchParamAction.remove, key: 'venue', value: '' },
                          ]);
                          setSelectedStates(newSelectedStates);
                          setSelectedCities([]);
                        }}
                      />
                    ))}
                  {props.displayFilterCity &&
                    selectedCities.map((city, index) => (
                      <VenueFilterPill
                        key={index}
                        prefix="City"
                        title={getFilterLabel(cities, city)}
                        value={city}
                        callbackRemove={(value: string) => {
                          const newSelectedCities = selectedCities.filter((item) => item !== value);
                          updateSearchParams([
                            { action: SearchParamAction.add, key: 'state', value: searchParams.get('state') },
                            {
                              action: newSelectedCities.length ? SearchParamAction.add : SearchParamAction.remove,
                              key: 'city',
                              value: newSelectedCities.join(','),
                            },
                            { action: SearchParamAction.remove, key: 'venue', value: '' },
                          ]);
                          setSelectedCities(newSelectedCities);
                        }}
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="flex flex-row justify-center items-center h-96">
          <Spinner />
        </div>
      )}
      <div className={twMerge('max-w-7xl space-y-12 mx-auto p-4 md:p-8', props.classList)}>
        {isLoading === false && items.length === 0 && (
          <div className="flex flex-row justify-center items-center">
            <span className="text-gray-300">No venues found</span>
          </div>
        )}
        {items.map((item, index) => (
          <VenueItem key={index} {...item} />
        ))}
      </div>
    </div>
  );
};
